<template>
  <Scrollbar
    style="max-width: 800px; margin: auto;"
    class="my-4"
  >
    <v-card class="px-4 mx-4">
      <h5 v-if="subscriptions.length === 0">
        {{ $t('subscriptions.empty') }}
      </h5>
      <v-list>
        <v-list-item
          v-for="subscription in subscriptions"
          :key="subscription.id"
          class="py-2"
          style="border-bottom: 1px solid #E0E0E0;"
        >
          <ProfileAvatar :avatar="cardAvatar(subscription)" />
          <v-list-item-content>
            <v-list-item-title>{{ subscription.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ cardSubtitle(subscription) }}</v-list-item-subtitle>
          </v-list-item-content>
          <h3>{{ cardPrice(subscription) }}</h3>
          <v-list-item-action>
            <v-btn
              color="error"
              variant="text"
              @click="() => cancelSubscription(subscription)"
              :loading="cancelingId === subscription.id"
              :disabled="!!cancelingId"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </Scrollbar>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import StripeService from '@api/stripe'
  import { Scrollbar } from '@components/App'
  import ProfileAvatar from '@components/Hall/ProfileAvatar'
  import { getResourceUrl } from '@utils'

  export default {
    components: {
      Scrollbar,
      ProfileAvatar,
    },

    data() {
      return {
        stripeService: null,
        subscriptions: [],
        cancelingId: ''
      }
    },

    async created() {
      this.stripeService = StripeService(this.user.id)
      const { subscriptions } = await this.stripeService.listSubscriptions()
      this.subscriptions = subscriptions
    },

    computed: {
      ...mapGetters({
        user: 'user'
      }),
    },

    methods: {
      ...mapMutations({
        setStripeSubscription: 'profile/setStripeSubscription'
      }),
      cardSubtitle(subscription) {
        if (subscription.community) return this.$t('subscriptions.community')
        return this.$t('subscriptions.hisolver')
      },
      cardAvatar(subscription) {
        if (subscription.community) return getResourceUrl(subscription.community.thumbnail)
        return null
      },
      cardPrice(subscription) {
        let priceValue = 0;
        let currency = 'USD';
        try {
          priceValue = subscription.items.data[0].price.unit_amount
          currency = subscription.items.data[0].price.currency
        } catch (err) {
          console.log(err)
        }
        const locale = currency === 'usd' ? 'en-US' : 'pt-BR'
        return new Intl.NumberFormat(locale, {
          style: 'currency',
          currency,
          minimumFractionDigits: 2
        }).format(priceValue / 100)
      },
      async cancelSubscription(subscription) {
        this.cancelingId = subscription.id
        try {
          await this.stripeService.cancelSubscription(subscription.id)
          this.setStripeSubscription(null)
          window.location.reload()
        } catch (err) {
          console.log(err)
        }
        this.cancelingId = ''
      },
      getResourceUrl
    }
  }
</script>